var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-snackbar',{attrs:{"top":"","timeout":3000},model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(_vm._s(_vm.snackbarText))]),_c('v-overlay',{staticClass:"loading-center",attrs:{"value":_vm.saving}},[_c('lottie',{attrs:{"options":_vm.defaultOptions,"height":150,"width":150},on:{"animCreated":_vm.handleAnimation}})],1),_c('v-card',[_c('v-card-title',{staticClass:"grid-close"},[_c('span',{staticClass:"headline"},[_vm._v("Direcciones de "+_vm._s(_vm.user.name)+" "+_vm._s(_vm.user.surname))]),_c('v-btn',{staticStyle:{"right":"10px","top":"10px"},attrs:{"icon":"","color":"primary","fab":"","absolute":""},on:{"click":function($event){return _vm.$emit('cancel')}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("fa-times")])],1)],1),_c('v-card-text',[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"12","sm":"12"}},[_c('v-text-field',{staticClass:"d-none d-md-flex",attrs:{"clearable":"","rounded":"","flat":"","filled":"","hide-details":"","label":`Buscar dirección`,"prepend-inner-icon":"fa-search"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[_c('v-data-table',{attrs:{"headers":_vm.getHeaders,"items":_vm.addresses,"items-per-page":10,"loading":_vm.loading,"sort-desc":true,"search":_vm.search,"footer-props":{
                itemsPerPageOptions: [10, 25, 50, 100, 250],
              }},scopedSlots:_vm._u([{key:`item.location`,fn:function({ item }){return [_c('a',{staticStyle:{"display":"block"},attrs:{"href":'https://www.google.com/maps/search/?api=1&query=' +
                    item.geo.geopoint.latitude +
                    ',' +
                    item.geo.geopoint.longitude,"target":"_blank"}},[_vm._v(" "+_vm._s(item.geo.geopoint.latitude ? parseFloat(item.geo.geopoint.latitude).toFixed(6) + "," : "no disponible")+_vm._s(item.geo.geopoint.longitude ? parseFloat(item.geo.geopoint.longitude).toFixed(6) : "")+" "),_c('i',{staticClass:"ml-1 fas fa-external-link-alt"})])]}},{key:`item.type`,fn:function({ item }){return [_c('v-icon',{staticStyle:{"font-size":"25px !important"},attrs:{"color":"primary m-3","large":"","dark":""}},[_vm._v(" "+_vm._s(item.type == "home" ? "fa-home" : item.type == "office" ? "fa-building" : "fa-map-pin")+" ")])]}},{key:`item.name`,fn:function({ item }){return [_c('p',{staticClass:"pa-0 ma-0",style:(item.deleted ? 'color:red' : '')},[_vm._v(" "+_vm._s(item.name)+" ")]),(item.deleted)?_c('small',{staticStyle:{"color":"red"}},[_vm._v("Eliminado por el usuario")]):_vm._e()]}},{key:`item.options`,fn:function({ item }){return [_c('v-btn',{staticClass:"mr-3",attrs:{"small":"","color":"primary"},on:{"click":function($event){return _vm.editAddress(item)}}},[_vm._v("Editar")])]}},{key:"loading",fn:function(){return [_c('div',{staticClass:"ma-5",staticStyle:{"position":"relative"}},[_c('div',{staticStyle:{"position":"absolute","z-index":"999","width":"100%","height":"100%","display":"flex","flex-direction":"column","justify-content":"center","align-items":"center"}},[_c('lottie',{attrs:{"options":_vm.defaultOptions,"height":300,"width":400},on:{"animCreated":_vm.handleAnimation}}),_c('p',{class:_vm.$vuetify.theme.dark
                          ? 'subtitle-1 primary--text'
                          : 'subtitle-1 black--text'},[_vm._v(" Cargando Data ")])],1),_c('v-skeleton-loader',{ref:"skeleton",staticClass:"mx-auto d-none d-md-block",attrs:{"type":"table-tbody"}})],1)]},proxy:true}],null,true)})],1)],1)],1)],1)],1),(_vm.selectedAddress)?_c('v-dialog',{attrs:{"max-width":"90%"},model:{value:(_vm.dialogLocation),callback:function ($$v) {_vm.dialogLocation=$$v},expression:"dialogLocation"}},[_c('map-c',{key:_vm.selectedAddress['.key'],ref:"map",attrs:{"longitude":_vm.selectedAddress.geo.geopoint.longitude,"latitude":_vm.selectedAddress.geo.geopoint.latitude,"name":`${_vm.selectedAddress.name} de ${_vm.user.name} ${_vm.user.surname}`},on:{"success":_vm.updateAddress,"cancel":function($event){_vm.dialogLocation = false}}})],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }