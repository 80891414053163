<template>
  <v-container fluid>
    <v-snackbar absolute top :timeout="3000" v-model="snackbar">{{
      snackbarText
    }}</v-snackbar>

    <v-overlay class="loading-center" :value="saving">
      <lottie
        :options="defaultOptions"
        :height="150"
        :width="150"
        v-on:animCreated="handleAnimation"
      />
    </v-overlay>

    <v-row class="pa-0 ma-0">
      <v-col cols="12" sm="12" md="11">
        <h1>Búsqueda de usuarios</h1>
      </v-col>

      <v-col class="two-columns" cols="12" sm="4" md="4">
        <i style="font-size: 20px; color: gray" class="fas fa-user mt-6"></i>
        <input
          v-on:keyup.enter="searchUsers"
          style="background-color: #f0f0f0; border: 0px; height: 50px"
          class="control-input"
          type="text"
          v-model="name"
          placeholder="Buscar por nombre"
        />
      </v-col>

      <v-col class="two-columns" cols="12" sm="4" md="4">
        <i style="font-size: 20px; color: gray" class="fas fa-phone mt-6"></i>
        <input
          v-on:keyup.enter="searchUsers"
          style="background-color: #f0f0f0; border: 0px; height: 50px"
          class="control-input"
          type="text"
          v-model="phone"
          placeholder="Buscar por número de teléfono"
        />
      </v-col>

      <v-col class="two-columns" cols="12" sm="4" md="4">
        <i
          style="font-size: 20px; color: gray"
          class="fas fa-envelope mt-6"
        ></i>
        <input
          v-on:keyup.enter="searchUsers"
          style="background-color: #f0f0f0; border: 0px; height: 50px"
          class="control-input"
          type="text"
          v-model="email"
          placeholder="Buscar por correo electrónico"
        />
      </v-col>

      <v-col class="two-columns" cols="12" sm="4" md="4">
        <i style="font-size: 20px; color: gray" class="fas fa-key mt-6"></i>
        <input
          v-on:keyup.enter="searchUsers"
          style="background-color: #f0f0f0; border: 0px; height: 50px"
          class="control-input"
          type="text"
          v-model="userId"
          placeholder="Buscar por ID del usuario"
        />
      </v-col>

      <v-col cols="12" sm="4" md="4">
        <v-select
          label="Filtrar por ciudad"
          prepend-icon="fas fa-city"
          item-text="name"
          item-value=".key"
          aria-autocomplete="false"
          autocomplete="off"
          hide-details
          :items="cities"
          filled
          v-model="selectedCity"
          rounded
          :allow-overflow="false"
          clearable
        >
        </v-select>
      </v-col>

      <v-col cols="12" sm="4" md="4">
        <v-select
          label="Filtrar por género"
          prepend-icon="fas fa-user-friends"
          item-text="text"
          item-value="value"
          aria-autocomplete="false"
          autocomplete="off"
          hide-details
          :items="gender"
          v-model="selectedGender"
          filled
          rounded
          :allow-overflow="false"
          clearable
        >
        </v-select>
      </v-col>

      <v-col cols="12" sm="4" md="4">
        <v-dialog
          ref="dialog"
          v-model="modal"
          :return-value="date"
          persistent
          width="290px"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              v-model="date"
              label="Filtrar por fecha de registro"
              prepend-icon="fa-calendar-alt"
              readonly
              filled
              rounded
              hide-details
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="date"
            range
            :title-date-format="title"
            scrollable
            :max="today"
          >
            <v-row no-gutters style="flex-wrap: nowrap">
              <v-btn
                class="flex-grow-1 flex-shrink-0"
                large
                rounded
                outlined
                color="primary"
                @click="restartDate"
                >Borrar
              </v-btn>

              <v-btn
                class="flex-grow-1 flex-shrink-0"
                large
                rounded
                color="primary"
                @click="modal = false"
                >Aceptar</v-btn
              >
            </v-row>
          </v-date-picker>
        </v-dialog>
      </v-col>

      <v-col cols="12" sm="4" md="4">
        <v-select
          label="Filtrar por estado"
          prepend-icon="fas fa-flag"
          item-text="text"
          item-value="value"
          aria-autocomplete="false"
          autocomplete="off"
          hide-details
          :items="status"
          v-model="selectedStatus"
          filled
          rounded
          :allow-overflow="false"
          clearable
        >
        </v-select>
      </v-col>

      <!--  -->

      <v-col class="mt-5" cols="12" sm="12" md="12">
        <v-row align="center" justify="end">
          <v-btn
            :loading="loading"
            v-if="users.length && ['sudo', 'admin'].includes(user.type)"
            @click="generateExcelFile($event)"
            id="U-001"
            class="save-btn mt-2 mr-5"
            color="primary"
          >
            <v-icon left dark> fas fa-file-excel </v-icon>
            Descargar .xlsx</v-btn
          >
          <v-btn
            style="width: 150px"
            :loading="loading"
            @click="searchUsers"
            class="save-btn mt-2"
            color="primary"
            >Buscar</v-btn
          >
        </v-row>
      </v-col>
    </v-row>

    <v-row class="pa-0 ma-0">
      <v-col cols="12">
        <v-data-table
          :headers="getHeaders"
          :items="getUsers"
          :items-per-page="15"
          :loading="loading"
          :sort-by="['orderCounter']"
          :sort-desc="true"
          item-key=".key"
          :search="$store.state.search"
          height="75vh"
          fixed-header
          :footer-props="{
            itemsPerPageOptions: [10, 25, 50, 100, 250],
          }"
        >
          <template v-slot:[`item.orderCounter`]="{ item }">
            {{ item.orderCounter || 0 }}
          </template>

          <template v-slot:[`item.gender`]="{ item }">
            {{ item.gender | filterGender }}
          </template>
          <!-- defaultCityId -->

          <template v-slot:[`item.phone`]="{ item }">
            <v-tooltip left>
              <template v-slot:activator="{ on }">
                <v-chip
                  :color="
                    $vuetify.theme.dark
                      ? 'rgba(0, 0, 0, 0.4)'
                      : 'rgba(0, 0, 0, 0.1)'
                  "
                  style="display: inline-block"
                  v-on="on"
                  dark
                  :text-color="$vuetify.theme.dark ? 'white' : 'black'"
                  @click="copyToClipboard(item.phone)"
                  >{{ item.phone }}</v-chip
                >
              </template>
              <i class="far fa-copy mr-2"></i>
              <span>Click para copiar</span>
            </v-tooltip>
          </template>

          <template v-slot:[`item.email`]="{ item }">
            <v-tooltip left>
              <template v-slot:activator="{ on }">
                <v-chip
                  :color="
                    $vuetify.theme.dark
                      ? 'rgba(0, 0, 0, 0.4)'
                      : 'rgba(0, 0, 0, 0.1)'
                  "
                  style="
                    display: inline-block;
                    width: 100%;
                    text-align: center !important;
                  "
                  v-on="on"
                  dark
                  :text-color="$vuetify.theme.dark ? 'white' : 'black'"
                  @click="copyToClipboard(item.email)"
                  >{{ item.email }}</v-chip
                >
              </template>
              <i class="far fa-copy mr-2"></i>
              <span>Click para copiar</span>
            </v-tooltip>
          </template>

          <template v-slot:[`item.isFucked`]="{ item }">
            <v-switch
              @change="
                switchControlChanged(
                  { isFucked: item.isFucked },
                  item['.key'],
                  item
                )
              "
              v-model="item.isFucked"
            ></v-switch>
          </template>

          <template v-slot:[`item.isLimitedFeatures`]="{ item }">
            <v-switch
              @change="
                switchLimitedFeatures(
                  { isLimitedFeatures: item.isLimitedFeatures },
                  item['.key'],
                  item
                )
              "
              v-model="item.isLimitedFeatures"
            ></v-switch>
          </template>

          <template v-slot:[`item.options`]="{ item }">
            <v-btn @click="editCards(item)" small color="primary"
              >Tarjetas</v-btn
            >
            <v-btn class="ml-3" @click="viewOrders(item)" small color="primary"
              >Compras</v-btn
            >
            <v-btn
              class="ml-3"
              @click="editAddresses(item)"
              small
              color="primary"
              >Direcciones</v-btn
            >
          </template>

          <template v-slot:loading>
            <div class="ma-5" style="position: relative">
              <div
                style="
                  position: absolute;
                  z-index: 999;
                  width: 100%;
                  height: 100%;
                  display: flex;
                  flex-direction: column;
                  justify-content: center;
                  align-items: center;
                "
              >
                <lottie
                  :options="defaultOptions"
                  :height="300"
                  :width="400"
                  v-on:animCreated="handleAnimation"
                />
                <p
                  :class="
                    $vuetify.theme.dark
                      ? 'subtitle-1 primary--text'
                      : 'subtitle-1 black--text'
                  "
                >
                  Cargando Data
                </p>
              </div>
              <v-skeleton-loader
                ref="skeleton"
                type="table-tbody"
                class="mx-auto d-none d-md-block"
              ></v-skeleton-loader>
            </div>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-dialog v-if="userSelected" v-model="modalEditCards" max-width="90%">
      <edit-cards
        @cancel="modalEditCards = false"
        :key="userSelected['.key']"
        :user="userSelected"
      ></edit-cards>
    </v-dialog>

    <v-dialog v-if="userSelected" v-model="modalViewOrders" max-width="90%">
      <user-orders
        @cancel="modalViewOrders = false"
        :key="userSelected['.key']"
        :user="userSelected"
      ></user-orders>
    </v-dialog>

    <v-dialog v-if="userSelected" v-model="dialogViewAddresses" max-width="90%">
      <user-addresses
        @cancel="dialogViewAddresses = false"
        :key="userSelected['.key']"
        :user="userSelected"
      ></user-addresses>
    </v-dialog>
  </v-container>
</template>

<script>
import { db, fb } from "@/firebase";
import Lottie from "../../../components/Lottie";
import * as animationData from "../../../assets/ochoColor.json";
import moment from "moment-timezone";
import "moment/locale/es";
moment.locale("es");
// import userDetails from './user-details'
import userOrders from "./user-orders";
import editCards from "./edit-cards";
import userAddresses from "./user-addresses";
import axios from "axios";
import { mapState } from "vuex";

export default {
  name: "all-users",
  components: {
    lottie: Lottie,
    // userDetails,
    editCards,
    userOrders,
    userAddresses,
  },
  data() {
    return {
      loading: true,
      totalUsers: 0,
      options: {},
      modal: null,
      snackbar: false,
      snackbarText: "",
      timer: null,
      name: "",
      date: [null, null],
      orderCounterRange: [0, 100],
      min: 0,
      max: 1000,

      selectedStatus: "",
      status: [{ text: "Usuarios bloqueados", value: "blocked" }],
      users: [],
      defaultOptions: {
        animationData: animationData.default,
        loop: true,
        autoplay: true,
      },
      anim: null,
      animationSpeed: 1.1,
      gender: [
        { text: "", value: null },
        { text: "Femenino", value: "female" },
        { text: "Masculino", value: "male" },
        { text: "Otro", value: "other2" },
      ],
      selectedGender: null,
      headers: [
        {
          text: "Nombre",
          value: "name",
        },
        {
          text: "Apellido",
          value: "surname",
        },
        {
          text: "Correo",
          value: "email",
        },
        {
          text: "Teléfono",
          value: "phone",
        },

        {
          text: "Género",
          value: "gender",
        },

        {
          text: "Ciudad por defecto",
          value: "cityName",
          width: "200px",
        },
        {
          text: "Características limitadas",
          value: "isLimitedFeatures",
          roles: ["sudo", "AF", "admin"],
        },
        {
          text: "Bloqueado",
          value: "isFucked",
          roles: ["sudo", "AF", "admin"],
        },
        {
          text: "# Órdenes completadas",
          value: "orderCounter",
          align: "center",
        },
        {
          value: "options",
          sortable: false,
          width: "400px",
          align: "end",
        },
      ],
      userSelected: null,
      phone: "",
      email: "",
      userId: "",
      modalEditCards: false,
      modalBlockUser: false,
      modalViewOrders: false,
      dialogViewAddresses: false,
      saving: false,
      cities: [],
      selectedCity: null,
    };
  },

  filters: {
    name(value) {
      let array = value.split("/");
      return `${array[0][0].toUpperCase()} ${array[1][0].toUpperCase()}`;
    },

    filterGender(gender) {
      let g = {
        male: "Masculino",
        female: "Femenino",
        other2: "Otro",
        "": "",
      };

      return g[gender];
    },

    // filterCityName() {
    //   // let cities = {}
    // }
  },

  computed: {
    ...mapState(["user"]),
    today() {
      return new Date().toISOString().substr(0, 10);
    },

    getHeaders() {
      return this.headers.filter(
        (item) =>
          !item.roles || (item.roles && item.roles.includes(this.user.type))
      );
    },

    getUsers() {
      let users = this.users;

      if (this.selectedStatus == "blocked") {
        users = this.users.filter((e) => e.isFucked);
      }

      return users.map((e) => {
        let city = this.cities.find((city) => city[".key"] == e.defaultCityId);
        e.cityName = city ? city.name : "";
        return e;
      });
    },
  },

  methods: {
    firstCapitalLetter(str) {
      return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
    },
    async searchByText() {
      this.loading = true;

      let resultByName = await this.searchByName();
      let resultBySurname = await this.searchBySurname();

      let usersArray = [...resultByName, ...resultBySurname];

      usersArray.forEach((user) => {
        let data = user.data();
        data[".key"] = user.id;
        this.users.push(data);
      });

      this.users = this.users.filter((item) =>
        `${item.name} ${item.surname}`
          .toLowerCase()
          .match(this.name.toLowerCase())
      );

      this.loading = false;
    },

    searchByName() {
      return new Promise(async (resolve, reject) => {
        let name = this.name.split(" ")[0];
        const userRef = db.collection("users");

        let firstCapitalLetter = this.firstCapitalLetter(name);

        const byName = userRef
          .where("name", ">=", name.toLowerCase())
          .where("name", "<=", name.toLowerCase() + "\uf8ff")
          .get();

        const byFirstCapitalLetter = userRef
          .where("name", ">=", firstCapitalLetter)
          .where("name", "<=", firstCapitalLetter + "\uf8ff")
          .get();

        const byCapitalLetter = userRef
          .where("name", ">=", name.toUpperCase())
          .where("name", "<=", name.toUpperCase() + "\uf8ff")
          .get();

        const [nameQuerySnapshot, firstCapitalLetterQuery, capitalLetterQuery] =
          await Promise.all([byName, byFirstCapitalLetter, byCapitalLetter]);

        const usersArray = [
          ...nameQuerySnapshot.docs,
          ...firstCapitalLetterQuery.docs,
          ...capitalLetterQuery.docs,
        ];

        return resolve(usersArray);
      });
    },

    searchBySurname() {
      return new Promise(async (resolve, reject) => {
        let name = this.name.split(" ")[0];
        const userRef = db.collection("users");

        let firstCapitalLetter = this.firstCapitalLetter(name);

        const byName = userRef
          .where("surname", ">=", name.toLowerCase())
          .where("surname", "<=", name.toLowerCase() + "\uf8ff")
          .get();

        const byFirstCapitalLetter = userRef
          .where("surname", ">=", firstCapitalLetter)
          .where("surname", "<=", firstCapitalLetter + "\uf8ff")
          .get();

        const byCapitalLetter = userRef
          .where("surname", ">=", name.toUpperCase())
          .where("surname", "<=", name.toUpperCase() + "\uf8ff")
          .get();

        const [nameQuerySnapshot, firstCapitalLetterQuery, capitalLetterQuery] =
          await Promise.all([byName, byFirstCapitalLetter, byCapitalLetter]);

        const usersArray = [
          ...nameQuerySnapshot.docs,
          ...firstCapitalLetterQuery.docs,
          ...capitalLetterQuery.docs,
        ];

        return resolve(usersArray);
      });
    },
    getSectionToken() {
      return new Promise((resolve, reject) => {
        fb.auth()
          .currentUser.getIdToken(true)
          .then((token) => {
            resolve(token);
          });
      });
    },
    handleAnimation(anim) {
      this.anim = anim;
      anim.setSpeed(this.animationSpeed);
    },

    async generateExcelFile(e) {
      let usersId = this.users.map((item) => {
        return item[".key"];
      });

      let token = await this.getSectionToken();
      this.loading = true;
      axios
        .post(
          `${process.env.VUE_APP_FUNCTIONS_URL}/httpUsersGenerateExcelFile`,
          {
            usersId,
          },
          { headers: { Authorization: `Basic ${token}` }, responseType: "blob" }
        )
        .then((response) => {
          this.loading = false;

          const { data, headers } = response;
          const fileName = `users.xlsx`;

          const blob = new Blob([data], { type: headers["content-type"] });
          let dom = document.createElement("a");
          let url = window.URL.createObjectURL(blob);
          dom.href = url;
          dom.download = decodeURI(fileName);
          dom.style.display = "none";
          document.body.appendChild(dom);
          dom.click();
          dom.parentNode.removeChild(dom);
          window.URL.revokeObjectURL(url);
        })
        .catch((err) => {
          this.loading = false;
          // console.log(err.response);
          this.snackbarText =
            "Ocurrió un error inesperado, inténtelo nuevamente.";
          this.snackbar = true;
        });
    },

    title() {
      return "Fecha de registro";
    },

    async switchControlChanged(data, userId, user) {
      this.saving = true;
      let body = {
        uid: user.uid,
        disabled: data.isFucked,
        key: user[".key"],
      };

      var httpUsersV2Disabled = fb
        .functions()
        .httpsCallable("httpUsersV2Disabled");
      httpUsersV2Disabled(body)
        .then((result) => {
          this.snackbarText = `Cuenta de usuario ${
            body.disabled ? "suspendida" : "habilitada"
          } exitosamente.`;
          this.snackbar = true;
          this.saving = false;
        })
        .catch(() => {
          this.saving = false;
          this.snackbarText =
            "Ocurrió un error inesperado, inténtelo nuevamente.";
          this.snackbar = true;
        });
    },

    editCards(user) {
      this.userSelected = user;
      this.modalEditCards = true;
    },

    switchLimitedFeatures(data, userId) {
      this.saving = true;
      db.collection("users")
        .doc(userId)
        .update(data)
        .then((res) => {
          this.snackbarText = `Información actualizada exitosamente.`;
          this.snackbar = true;
          this.saving = false;
        })
        .catch((err) => {
          this.saving = false;
          this.snackbarText =
            "Ocurrió un error inesperado, inténtelo nuevamente.";
          this.snackbar = true;
        });
    },

    editAddresses(user) {
      this.userSelected = user;
      this.dialogViewAddresses = true;
    },
    viewOrders(user) {
      this.userSelected = user;
      this.modalViewOrders = true;
    },
    copyToClipboard(text) {
      navigator.clipboard.writeText(text).then(
        () => {
          this.snackbar = true;
          this.snackbarText = "Copiado al portapapeles";
        },
        (err) => {
          console.error("Async: Could not copy text: ", err);
        }
      );
    },

    searchUsers() {
      this.users = [];
      if (this.name) {
        this.searchByText();
        return;
      }

      if (this.phone || this.email || this.userId) {
        this.specificUserSearch();
        return;
      }

      if (
        (this.date[0] && this.date[1]) ||
        this.selectedGender ||
        this.selectedCity
      ) {
        this.generalSearch();
        return;
      }

      // if (this.selectedCity) {
      //   this.searchByCity();
      //   return;
      // }

      this.snackbarText = "Ingrese al menos 1 parametro de búsqueda";
      this.snackbar = true;
    },

    restartDate() {
      this.date = [null, null];
      this.modal = false;
    },

    async searchByCity() {
      this.loading = true;
      await this.$binding(
        "users",
        db.collection("users").where("defaultCityId", "==", this.selectedCity)
      );

      this.loading = false;
    },

    async generalSearch() {
      let query = db.collection("users");
      this.users = [];
      this.loading = true;

      if (this.selectedGender) {
        query = query.where("gender", "==", this.selectedGender);
      }

      if (this.date[0] && this.date[1]) {
        query = query
          .where(
            "created",
            ">",
            moment(this.date[0], "YYYY-MM-DD").startOf("day").toDate()
          )
          .where(
            "created",
            "<",
            moment(this.date[1], "YYYY-MM-DD").endOf("day").toDate()
          );
      }

      if (this.selectedCity) {
        query = query.where("defaultCityId", "==", this.selectedCity);
      }

      await this.$binding("users", query);

      this.loading = false;
    },

    specificUserSearch() {
      let query = "";
      this.loading = true;
      this.users = [];
      if (this.userId) query = db.collection("users").doc(this.userId);
      else if (this.phone)
        query = db.collection("users").where("phone", "==", this.phone);
      else if (this.email)
        query = db.collection("users").where("email", "==", this.email);

      if (query) {
        query.get().then((res) => {
          this.loading = false;
          if (res.size > 0) {
            let user = res.docs[0].data();
            user[".key"] = res.docs[0].id;
            this.users.push(user);
          } else if (this.userId) {
            let user = res.data();
            user[".key"] = res.id;
            this.users.push(user);
          } else {
            this.snackbarText = "No se encontraron resultados";
            this.snackbar = true;
          }
        });
      }
    },
  },

  mounted() {
    this.loading = false;

    this.$binding(
      "cities",
      db.collection("cities").orderBy("name", "asc")
    ).then(() => {
      this.cities = this.cities.filter((e) => !e.deleted);
    });
  },
};
</script>

<style scoped>
.two-columns {
  display: grid !important;
  grid-template-columns: 10% 90% !important;
}
</style>