<template>
  <div>
    <v-snackbar top :timeout="3000" v-model="snackbar">{{
      snackbarText
    }}</v-snackbar>
    <map-skeleton v-if="loading" />
    <v-overlay class="loading-center" v-if="saving">
      <lottie
        :options="defaultOptions"
   :height="150"
        :width="150"
        v-on:animCreated="handleAnimation"
      />
    </v-overlay>

    <v-card>
      <v-card-title class="grid-close">
        <span class="headline">Ubicación de {{ name }}</span>
        <v-btn
          style="right: 10px; top: 10px"
          icon
          color="primary"
          fab
          absolute
          @click="$emit('cancel')"
        >
          <v-icon dark>fa-times</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-container fluid v-if="!loading">
          <v-row class="pt-3 ml-2">
            <v-col md="12" cols="12" sm="12">
              <l-map
                @update:center="centerUpdated"
                class="fill-height"
                style="height: 50vh"
                :zoom="zoom"
                :center="center"
              >
                <l-tile-layer :url="url"></l-tile-layer>
                <l-marker
                  :lat-lng="[center[0], center[1]]"
                  @update:lat-lng="positionUpdated"
                  :draggable="true"
                  :icon="getImage"
                >
                  <l-tooltip>Mueva el marcador a la nueva ubicación</l-tooltip>
                </l-marker>
                <l-geo-json
                  :options-style="styleFunction"
                  :options="options"
                  :geojson="geojson"
                />
                <v-geosearch :options="geosearchOptions"></v-geosearch>
              </l-map>
            </v-col>
            <v-col cols="12" xs="6" sm="6" md="6">
              <h2>Latitud</h2>
              <input
                type="number"
                class="control-input control-input-number"
                placeholder="Latitud"
                v-model="center[0]"
              />
            </v-col>

            <v-col cols="12" xs="6" sm="6" md="6">
              <h2>Longitud</h2>
              <input
                type="number"
                class="control-input control-input-number"
                placeholder="Longitud"
                v-model="center[1]"
              />
            </v-col>
          </v-row>

          <v-row class="pa-0 ma-0">
            <v-col cols="12" xs="12" sm="12" md="12">
              <v-divider></v-divider>
              <v-row justify="end">
                <v-btn @click="success" class="save-btn" color="primary" dark
                  >Guardar</v-btn
                >
              </v-row>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import Lottie from "./Lottie";
import * as animationData from "../assets/ochoColor.json";
// import { Parser } from "json2csv";
// import { mapActions, mapState } from 'vuex';
import { db } from "@/firebase";

import {
  LMap,
  LTileLayer,
  LIcon,
  LMarker,
  LPopup,
  LTooltip,
  LGeoJson,
} from "vue2-leaflet";
import "leaflet/dist/leaflet.css";
import mapSkeleton from "./skeleton/map-skeleton";
import { OpenStreetMapProvider } from "leaflet-geosearch";
import VGeosearch from "vue2-leaflet-geosearch";

export default {
  name: "map-c",
  props: ["longitude", "latitude", "name"],
  components: {
    lottie: Lottie,
    LMap,
    LTileLayer,
    LIcon,
    LMarker,
    LPopup,
    mapSkeleton,
    LTooltip,
    VGeosearch,
    LGeoJson,
  },
  data() {
    return {
      geosearchOptions: {
        showMarker: false,
        provider: new OpenStreetMapProvider(),
        animateZoom: true,
        autoClose: true,
        searchLabel: "Buscar comercio",
      },
      url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
      // url:"https://{s}.tile.openstreetmap.fr/osmfr/{z}/{x}/{y}.png",
      zoom: 0,
      center: [47.41322, -1.219482],
      staticAnchor: [16, 37],
      iconSize: 32,
      icon: L.icon(this.getImage),
      animationSpeed: 1.1,
      defaultOptions: {
        animationData: animationData.default,
        loop: true,
        autoplay: true,
      },
      snackbar: false,
      snackbarText: "",
      loading: true,
      saving: false,
      geojson: null,
    };
  },

  watch: {
    zoom() {
      if (this.zoom < 28) {
        setTimeout(() => {
          this.zoom += 2;
        }, 100);
      }
    },
  },

  computed: {
    dynamicSize() {
      return [this.iconSize, this.iconSize * 1];
    },
    dynamicAnchor() {
      return [this.iconSize / 2, this.iconSize * 1];
    },

    getImage() {
      return L.icon({
        iconUrl: require("../assets/user.svg"),
        shadowUrl: require("../assets/map-marker.png"),
        iconSize: [32, 32],
        shadowSize: [64, 64],
        iconAnchor: [-12, 56],
        shadowAnchor: [4, 62],
        popupAnchor: [-3, -76],
      });
    },
    options() {
      return {
        onEachFeature: this.onEachFeatureFunction,
      };
    },
    styleFunction() {
      const fillColor = this.fillColor;
      return () => {
        return {
          weight: 2,
          color: "#ff0000",
          opacity: 0.1,
          fillColor: fillColor,
          fillOpacity: 0.1,
        };
      };
    },
  },
  methods: {
    positionUpdated(e) {
      this.center = [e.lat, e.lng];
    },

    centerUpdated(e) {
      this.center = [e.lat, e.lng];
    },

    handleAnimation: function (anim) {
      this.anim = anim;
      anim.setSpeed(this.animationSpeed);
    },
    success() {
      if (this.center[0] && this.center[1]) {
        this.saving = true;
        this.$emit("success", this.center);
      } else {
        this.snackbarText = "Ubicación no válida.";
        this.snackbar = true;
      }
    },
    handleError() {
      this.snackbarText = "Ocurrió un error inesperado, inténtelo nuevamente.";
      this.snackbar = true;
      this.saving = false;
    },
  },

  async mounted() {
    this.$store.state.visibleSearch = false;
    this.center = [this.latitude, this.longitude];
    this.zoom = 1;
    // this.loading = false;

    await this.$binding(
      "globals",
      db.collection(`settings`).doc("global")
    ).then(async (globals) => {
      this.geojson = JSON.parse(globals.coverageZone);

      this.geojson.features = this.geojson.features.filter(
        (e) => e.properties.color != "transparent"
      );

      this.loading = false;
    });
  },
};
</script>

<style lang="scss">
#mapid {
  height: 40vh;
}
.leaflet-marker-icon {
  border-radius: 100%;
  background-color: #f96921;
  background-size: cover;
  animation-name: in;
  animation-duration: 3s;
}

.leaflet-shadow-pane {
  animation-name: in;
  animation-duration: 3s;
}

@keyframes in {
  0% {
    left: -200px;
    top: -100px;
  }
  25% {
    left: 0;
    top: 0;
  }
}

.save-btn {
  margin-top: 20px;
}

.vue2leaflet-map {
  z-index: 1;
}
</style>

