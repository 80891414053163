<template>
  <div>
    <v-snackbar top :timeout="3000" v-model="snackbar">{{
      snackbarText
    }}</v-snackbar>
    <v-overlay class="loading-center" :value="saving">
      <lottie
        :options="defaultOptions"
   :height="150"
        :width="150"
        v-on:animCreated="handleAnimation"
      />
    </v-overlay>

    <v-card>
      <v-card-title class="grid-close">
        <span class="headline"
          >Direcciones de {{ user.name }} {{ user.surname }}</span
        >
        <v-btn
          style="right: 10px; top: 10px"
          icon
          color="primary"
          fab
          absolute
          @click="$emit('cancel')"
        >
          <v-icon dark>fa-times</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-container fluid>
          <v-row>
            <v-col cols="12" md="12" sm="12">
              <v-text-field
                v-model="search"
                clearable
                rounded
                flat
                filled
                hide-details
                :label="`Buscar dirección`"
                prepend-inner-icon="fa-search"
                class="d-none d-md-flex"
              />
            </v-col>

            <v-col cols="12" sm="12" md="12">
              <v-data-table
                :headers="getHeaders"
                :items="addresses"
                :items-per-page="10"
                :loading="loading"
                :sort-desc="true"
                :search="search"
                :footer-props="{
                  itemsPerPageOptions: [10, 25, 50, 100, 250],
                }"
              >
                <template v-slot:[`item.location`]="{ item }">
                  <a
                    style="display: block"
                    :href="
                      'https://www.google.com/maps/search/?api=1&query=' +
                      item.geo.geopoint.latitude +
                      ',' +
                      item.geo.geopoint.longitude
                    "
                    target="_blank"
                  >
                    {{
                      item.geo.geopoint.latitude
                        ? parseFloat(item.geo.geopoint.latitude).toFixed(6) +
                          ","
                        : "no disponible"
                    }}{{
                      item.geo.geopoint.longitude
                        ? parseFloat(item.geo.geopoint.longitude).toFixed(6)
                        : ""
                    }}
                    <i class="ml-1 fas fa-external-link-alt"></i>
                  </a>
                </template>

                <template v-slot:[`item.type`]="{ item }">
                  <v-icon
                    color="primary m-3"
                    style="font-size: 25px !important"
                    large
                    dark
                  >
                    {{
                      item.type == "home"
                        ? "fa-home"
                        : item.type == "office"
                        ? "fa-building"
                        : "fa-map-pin"
                    }}
                  </v-icon>
                </template>

                <template v-slot:[`item.name`]="{ item }">
                  <p :style="item.deleted ? 'color:red' : ''" class="pa-0 ma-0">
                    {{ item.name }}
                  </p>
                  <small style="color: red" v-if="item.deleted"
                    >Eliminado por el usuario</small
                  >
                </template>

                <template v-slot:[`item.options`]="{ item }">
                  <v-btn
                    @click="editAddress(item)"
                    small
                    color="primary"
                    class="mr-3"
                    >Editar</v-btn
                  >
                </template>

                <template v-slot:loading>
                  <div class="ma-5" style="position: relative">
                    <div
                      style="
                        position: absolute;
                        z-index: 999;
                        width: 100%;
                        height: 100%;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                      "
                    >
                      <lottie
                        :options="defaultOptions"
                        :height="300"
                        :width="400"
                        v-on:animCreated="handleAnimation"
                      />
                      <p
                        :class="
                          $vuetify.theme.dark
                            ? 'subtitle-1 primary--text'
                            : 'subtitle-1 black--text'
                        "
                      >
                        Cargando Data
                      </p>
                    </div>
                    <v-skeleton-loader
                      ref="skeleton"
                      type="table-tbody"
                      class="mx-auto d-none d-md-block"
                    ></v-skeleton-loader>
                  </div>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>

      <!-- <v-card-actions>
        <v-container fluid>
          <v-divider></v-divider>
          <v-row class="pa-0 ma-0">
            <v-col cols="12" xs="12" sm="12" md="12">
              <v-row align="center" justify="end">
                <v-btn @click="$emit('cancel')" class="save-btn" color="primary"
                  >Cerrar</v-btn
                >
              </v-row>
            </v-col>
          </v-row>
        </v-container>
      </v-card-actions> -->
    </v-card>

    <v-dialog v-if="selectedAddress" v-model="dialogLocation" max-width="90%">
      <map-c
        ref="map"
        @success="updateAddress"
        @cancel="dialogLocation = false"
        :longitude="selectedAddress.geo.geopoint.longitude"
        :latitude="selectedAddress.geo.geopoint.latitude"
        :name="`${selectedAddress.name} de ${user.name} ${user.surname}`"
        :key="selectedAddress['.key']"
      ></map-c>
    </v-dialog>
  </div>
</template>

<script>
import { db, fb } from "@/firebase";
import Lottie from "../../../components/Lottie";
import * as animationData from "../../../assets/ochoColor.json";
// import { Parser } from "json2csv";
import moment from "moment-timezone";
import "moment/locale/es";
moment.locale("es");
// import { mapActions, mapState } from "vuex";
import mapC from "@/components/Map";
// import axios from "axios";
// import { mapState } from "vuex";

export default {
  name: "edit-user-cards",
  props: ["user"],
  components: {
    lottie: Lottie,
    mapC,
  },
  data() {
    return {
      animationSpeed: 1.1,
      defaultOptions: {
        animationData: animationData.default,
        loop: true,
        autoplay: true,
      },

      snackbar: false,
      userRole : null,
      snackbarText: "",
      saving: false,
      addresses: [],
      selectedAddress: null,
      dialogLocation: false,
      loading: true,
      cities: [],
      allowedRoles: ["sudo", "admin", "AF"],
      search: "",
      headers: [
        {
          value: "type",
        },
        {
          text: "Nombre",
          value: "name",
          width: "300px",
        },
        {
          text: "Referencia",
          value: "neighborhood",
          width: "300px",
        },
        {
          text: "Ciudad",
          value: "cityName",
        },
        {
          text: "Zona",
          value: "zone",
          width: "150px",
        },

        {
          text: "Localización",
          value: "location",
        },
        {
          value: "options",
          align: "end",
          roles  : ['sudo', 'admin', 'AF']
        },
      ],
    };
  },

  computed: {
    getHeaders() {
      return this.headers.filter( item => !item.roles || (item.roles && item.roles.includes(this.userRole)))
    }
  },
  async mounted() {
    await db
      .collection("cities")
      .get()
      .then((response) => {
        response.forEach((item) => {
          let city = {};
          city.name = item.data().name;
          city[".key"] = item.id;
          this.cities.push(city);
        });
      });

    this.getAddresses();

    this.userRole = this.$store.state.user.type
  },
  methods: {
    handleAnimation (anim) {
      this.anim = anim;
      anim.setSpeed(this.animationSpeed);
    },
    editAddress(item) {
      this.selectedAddress = item;
      this.dialogLocation = true;
    },
    getAddresses() {
      this.addresses = [];
      this.loading = true;
      db.collection("users")
        .doc(this.user[".key"])
        .collection("addresses")
        .orderBy("deleted")
        .get()
        .then((data) => {
          data.forEach((item) => {
            let address = item.data();
            address[".key"] = item.id;
            address.cityName = this.cities.find(
              (element) => element[".key"] == address.city
            ).name;
            this.addresses.push(address);
          });
          this.loading = false;
        });
    },

    async updateAddress(location) {
      // let token = await this.getSectionToken;
      let data = {
        lat: location[0],
        lng: location[1],
        userId: this.user[".key"],
        addressId: this.selectedAddress[".key"],
      };

      var httpUsersV2UpdateAddressGeoPoint = fb
        .functions()
        .httpsCallable("httpUsersV2UpdateAddressGeoPoint");
      httpUsersV2UpdateAddressGeoPoint(data)
        .then((result) => {
          this.getAddresses();
          this.$refs.map.saving = false;
          this.dialogLocation = false;
          this.snackbarText = "Dirección actualizada exitosamente.";
          this.snackbar = true;
        })
        .catch((err) => {
          this.$refs.map.handleError();
        });
    },
  },
};
</script>